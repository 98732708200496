<template>
    <div class="navbar">
        <div class='d-flex align-items-center'>  
            <!-- <img alt="." height='60' width='60' src="@/assets/images/logo_znaem_vse.png"> -->
            <a href='/' class="d-flex flex-column no-decor" style="color:unset">
                <div class="d-flex flex-column align-items-start" style="font-size:2.5rem; line-height: normal">
                    Знаем все
                    <span style="margin-top:-4px; letter-spacing: 9.2px; color:dimgrey; font-size:1rem">стоматологии</span>
                </div>
            </a>
        </div>
        <!-- <div class="navbar-main-menu hide-mobile">
            <div>
                <a href="">
                    База данных
                </a>
            </div>
            <div>Статистика</div>
            <div>Врачи</div>


        </div> -->
        <div v-if='isLoggedIn' class="navbar-right-menu">
            <div class="d-flex flex-column align-items-end">
                <div style="font-size:.9rem" class="cursor-pointer">{{ email }}</div>
                <div style="color: grey; font-size:.8rem">Осталось дней: {{ daysLeft }}</div>
            </div>
            <img src="@/assets/images/default_avatar.png" class='cursor-pointer me-4' @click='go_to_profile' height="50" width="50" style="border-radius: 100px; object-fit: cover;">
            <button @click='logout' class="btn btn-outline-primary">Выход</button>
        </div>
        <div v-else class="d-flex" style="gap:24px">
            <a href="/login" class="btn btn-outline-primary no-decor">Вход</a>
            <a href="/register" class="btn btn-primary no-decor">Регистрация</a>
        </div>
    </div>

</template>


<script>
import { computed } from 'vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useUserStore } from '@/store/modules/users';
import axios from '@/assets/js/axios.js'
import router from '@/routers/Router.js'

export default {
    name: 'Navbar',
    setup(){
        const userStore = useUserStore();
        const email = computed(() => {
            return userStore.getEmail
        })
        const daysLeft = computed(() => {
            return userStore.getDaysLeft
        })
        const isLoggedIn = computed(() => {
            return userStore.getEmail !== '';
        });
        
        

        const intervalId = ref(null);
        const token = localStorage.getItem('access_token');
        const get_navbar_user_data = async () => {
            try {
                const response = await axios.get('api/v1/users/get_navbar_user_data');
                userStore.setUser(response.data.email, response.data.days_left);
            } catch (error) {
                // console.error('Error fetching data:', error);
            }
        };
        onMounted(() => {
            if (token){
            get_navbar_user_data(); // Fetch data immediately when component is mounted
            intervalId.value = setInterval(get_navbar_user_data, 60000); // Poll every minute
            window.addEventListener('load', get_navbar_user_data)
            }
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId.value); // Clear interval when component is unmounted
        });

        const logout = () => {
            localStorage.removeItem('access_token');
            userStore.clearUser()
            router.push('/login');
        };
        const go_to_profile = () => {
            router.push('/profile');
        }

        return{
            email,
            daysLeft,
            isLoggedIn,
            logout,
            go_to_profile
        }
    },
   
}
</script>


<style>

</style>
